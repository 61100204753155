<template>
  <div class="flex flex-col px-normal pt-sm text-left content-between text-left">
    <div class="flex-1">
      <h3 class="text-xl font-bold">Cantidad de acompañantes permitidos por foodie</h3>
    </div>

    <div class="fex-1 block custom-width mx-auto justify-items-center mt-2">
      <div class="wrapper flex-none grid grid-cols-3 gap-4 text-center items-center">

        <input type="radio" name="select" v-model="selectedCompanion" value="1" id="option-1" @change="$emit('update:companions', parseInt($event.target.value))">
        <input type="radio" name="select" v-model="selectedCompanion" value="2" id="option-2" @change="$emit('update:companions', parseInt($event.target.value))">
        <input type="radio" name="select" v-model="selectedCompanion" value="3" id="option-3" @change="$emit('update:companions', parseInt($event.target.value))">
        <input type="radio" name="select" v-model="selectedCompanion" value="4" id="option-4" @change="$emit('update:companions', parseInt($event.target.value))">
        <input type="radio" name="select" v-model="selectedCompanion" value="5" id="option-5" @change="$emit('update:companions', parseInt($event.target.value))">
        <input type="radio" name="select" v-model="selectedCompanion" value="0" id="option-0" @change="$emit('update:companions', parseInt($event.target.value))">

        <label for="option-1" class="flex items-center option option-1 rounded-full border border-dgray">
          <span class="flex-auto">
            <span class="title block text-xl font-bold">1</span>
          </span>
        </label>

        <label for="option-2" class="flex items-center option option-2 rounded-full border border-dgray">
          <span class="flex-auto">
            <span class="title block text-xl font-bold">2</span>
          </span>
        </label>

        <label for="option-3" class="flex items-center option option-3 rounded-full border border-dgray">
          <span class="flex-auto">
            <span class="title block text-xl font-bold">3</span>
          </span>
        </label>

        <label for="option-4" class="flex items-center option option-4 rounded-full border border-dgray">
          <span class="flex-auto">
            <span class="title block text-xl font-bold">4</span>
          </span>
        </label>

        <label for="option-5" class="flex items-center option option-5 rounded-full border border-dgray">
          <span class="flex-auto">
            <span class="title block text-xl font-bold">5</span>
          </span>
        </label>

        <label for="option-0" class="flex items-center option option-0 rounded-full border border-dgray">
          <span class="flex-auto">
            <span class="title block text-xl font-bold">0</span>
          </span>
        </label>

      </div>
    </div>
    <div class="flex-1"></div>
  </div>
</template>

<script>
export default {
  name: 'step-companions',
  props: {
    companions: { required: false, type: Number, default: 1 }
  },
  emits: ['update:companions'],
  data () {
    return {
      selectedCompanion: 1
    }
  },
  mounted () {
    this.selectedCompanion = this.companions
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.wrapper .option{
  align-items: center;
  justify-content: space-evenly;
  border-radius: 50%;
  cursor: pointer;
  background: #efefef;
  transition: all 0.3s ease;
}
.option {
  min-width: 50px !important;
  min-height: 50px !important;
  width: 50px !important;
  height: 50px !important;
}
input[type="radio"]{
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2,
#option-3:checked:checked ~ .option-3,
#option-4:checked:checked ~ .option-4,
#option-5:checked:checked ~ .option-5,
#option-0:checked:checked ~ .option-0{
  /*border-color: #cf1a1a;*/
  background-color: #397EF3;
  color: #fff;
}

#option-1:checked:checked ~ .option-1 button,
#option-2:checked:checked ~ .option-2 button,
#option-3:checked:checked ~ .option-3 button,
#option-4:checked:checked ~ .option-4 button,
#option-5:checked:checked ~ .option-5 button,
#option-0:checked:checked ~ .option-0 button{
  /*border-color: #cf1a1a;*/
  background-color: #397EF3;
  border: none;
}
</style>
