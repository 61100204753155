<template>
  <div class="flex flex-col px-normal pt-sm text-left content-between text-left">
    <div class="flex-1">
      <h3 class="text-xl font-bold">Requerimientos</h3>
    </div>

    <div class="mt-4">
      <div class="text-right text-gray text-sm -mt-2">
        <p>{{ requirements.length  + ' / 400' }}</p>
      </div>
      <textarea
        v-model="requirementValue"
        maxlength="400"
        rows="6"
        placeholder="(opcional)"
        class="border rounded-xl w-full p-2 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 disabled:bg-gray">
      </textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: 'step-requirements',
  props: {
    requirements: { required: false, type: String, default: '' }
  },
  emits: ['update:requirements'],
  data () {
    return {
      requirementValue: ''
    }
  },
  // methods: {
  //   handleInput (event) {
  //     console.log(event)
  //   }
  // }
  watch: {
    requirementValue (value) {
      // const newValue = value
      if (value.length > 400) {
        this.requirementValue = value.slice(0, 400)
        this.$emit('update:requirements', value.slice(0, 400))
      } else {
        this.$emit('update:requirements', value)
      }
    }
  },
  mounted () {
    this.requirementValue = this.requirements
  }
}
</script>

<style scoped>

</style>
