<template>
  <div class="flex flex-col px-normal pt-sm text-left content-between text-left">
    <div class="flex-1">
      <h3 class="text-xl font-bold">{{ foodiesMeeting ? 'Elige fecha y hora' : 'Elige un rango de fecha'}}</h3>
    </div>

    <div class="flex-1 pb-30 mt-2">
      <div class="text-center">
        <div v-if="foodiesMeeting" >
          <Calendar class="flex-col" v-model="date" :inline="true" date-format="dd/mm - " :minDate="minDate" />
          <div class="flex text-xxl text-center justify-center items-center">
            <div id="hours" class="flex flex-col justify-center">
              <button class="rounded-full" type="button" @click="formatHour('increase')">
                <span class="pi pi-chevron-up"></span>
              </button>
              <p>{{ formattedHour }}</p>
              <button class="rounded-full" type="button" @click="formatHour('decrease')">
                <span class="pi pi-chevron-down"></span>
              </button>
            </div>

            <div class="align-middle text-xl mx-4">:</div>

            <div id="minutes" class="flex flex-col justify-center">
              <button class="rounded-full" type="button" @click="formatMinutes('increase')">
                <span class="pi pi-chevron-up"></span>
              </button>
              <p>{{ formattedMinutes }}</p>
              <button class="rounded-full" type="button" @click="formatMinutes('decrease')">
                <span class="pi pi-chevron-down"></span>
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <Calendar v-if="days === '2' || days === 2" v-model="date" :inline="true" date-format="dd/mm - " :minDate="today" selectionMode="range" />
          <Calendar v-else v-model="date" :inline="true" date-format="dd/mm/ - " :minDate="today" selectionMode="range" :disabledDays="[0,5,6]" />
        </div>
      </div>

      <div v-if="!foodiesMeeting" class="wrapper flex-none flex text-center items-center mt-normal text-lg lg:w-4/5 lg:mx-auto lg:mt-14">

        <input type="radio" name="select" v-model="days" id="option-1" value="1" @change="$emit('update:weekDays', [1, 2, 3, 4])">
        <input type="radio" name="select" v-model="days" id="option-2" value="2" @change="$emit('update:weekDays', [1, 2, 3, 4, 5, 6, 7])">

        <label for="option-1" class="flex items-center flex-1 option option-1 border rounded-full border-dgray h-8 mx-sm">
          <span class="flex-auto">
            <span class="title block">Lunes a Jueves</span>
          </span>
        </label>

        <label for="option-2" class="flex items-center flex-1 option option-2 border rounded-full border-dgray h-8 mx-sm">
          <span class="flex-auto">
            <span class="title block">Toda la semana</span>
          </span>
        </label>
      </div>
    </div>

    <div class="flex-1 flex items-end pb-4">

    </div>
  </div>
</template>

<script>
import Calendar from 'primevue/calendar'
import { ref } from 'vue'

export default {
  name: 'step-calendar',
  props: {
    startAt: { required: false },
    endAt: { required: false },
    weekDays: { required: false, type: Array },
    foodiesMeeting: { requited: false }
  },
  setup () {
    const today = new Date()
    const month = today.getMonth()
    const year = today.getFullYear()
    const minDate = ref(new Date())
    const prevMonth = (month === 0) ? 11 : month - 1
    const prevYear = (prevMonth === 11) ? year - 1 : year
    const hours = ref(20)
    const formattedHour = ref('20')
    const minutes = ref(0)
    const formattedMinutes = ref('00')

    const formatHour = (type) => {
      if (type === 'increase') {
        hours.value = hours.value < 23 ? hours.value + 1 : 23
      } else {
        hours.value = hours.value > 0 ? hours.value - 1 : 0
      }
      const aux = hours.value < 10 ? '0' + hours.value : hours.value
      formattedHour.value = aux.toString()
    }

    const formatMinutes = (type) => {
      if (type === 'increase') {
        // minutes.value += 15
        // minutes.value = minutes.value > 45 ? 0 : minutes.value + 15
        minutes.value = minutes.value < 45 ? minutes.value + 15 : 0
      } else {
        // minutes.value -= 15
        minutes.value = minutes.value > 0 ? minutes.value - 15 : 45
      }
      const aux = minutes.value < 10 ? '0' + minutes.value : minutes.value
      formattedMinutes.value = aux.toString()
    }

    minDate.value.setMonth(month)
    minDate.value.setFullYear(prevYear)
    return { minDate, today, hours, minutes, formattedHour, formattedMinutes, formatHour, formatMinutes }
  },
  emits: ['disable-next', 'update:startAt', 'update:endAt', 'update:weekDays'],
  components: {
    Calendar
  },
  data () {
    return {
      date: '',
      days: 1
    }
  },
  watch: {
    date: function (value) {
      if (this.foodiesMeeting) {
        const newDate = new Date(value)
        newDate.setHours(this.hours)
        newDate.setMinutes(this.minutes)
        this.$emit('update:startAt', this.$filters.moment(newDate, 'YYYY-MM-DD HH:mm'))
        this.$emit('update:endAt', this.$filters.moment(newDate, 'YYYY-MM-DD HH:mm'))
        this.$emit('disable-next', false)
      } else {
        this.$emit('update:startAt', this.$filters.moment(value[0], 'YYYY-MM-DD'))
        this.$emit('update:endAt', this.$filters.moment(value[1], 'YYYY-MM-DD'))
      }
      if (this.startAt && this.endAt) {
        this.$emit('disable-next', false)
      }
    },
    formattedHour: function (value) {
      const newDate = new Date(this.date)
      newDate.setHours(value)
      newDate.setMinutes(this.minutes)

      this.$emit('update:startAt', this.$filters.moment(newDate, 'YYYY-MM-DD HH:mm'))
      this.$emit('update:endAt', this.$filters.moment(newDate, 'YYYY-MM-DD HH:mm'))
      this.$emit('disable-next', false)
    },
    formattedMinutes: function (value) {
      const newDate = new Date(this.date)
      newDate.setHours(this.hours)
      newDate.setMinutes(value)

      this.$emit('update:startAt', this.$filters.moment(newDate, 'YYYY-MM-DD HH:mm'))
      this.$emit('update:endAt', this.$filters.moment(newDate, 'YYYY-MM-DD HH:mm'))
      this.$emit('disable-next', false)
    }
  },
  mounted () {
    // this.date = this.startAt + this.endAt
    this.days = this.weekDays.length > 4 ? 2 : 1
    this.$emit('disable-next', true)
    this.$emit('update:startAt', '')
    this.$emit('update:endAt', '')
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.p-calendar.p-datepicker {
  border-radius: 10px !important;
}
.wrapper .option{
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  border-color: #707070;
  color: #707070;
  transition: all 0.3s ease;
}
input[type="radio"]{
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2,
#option-3:checked:checked ~ .option-3,
#option-4:checked:checked ~ .option-4,
#option-5:checked:checked ~ .option-5,
#option-0:checked:checked ~ .option-0{
  /*border-color: #cf1a1a;*/
  background-color: #6D6D6D;
  color: #fff;
}
</style>
