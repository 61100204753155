<template>
  <div class="flex flex-col px-normal pt-sm text-left content-between text-left">
    <div class="flex-1">
      <h3 class="text-xl font-bold">Tipo de descuento</h3>
      <p class="text-sm">Elige ofrecer un porcentaje de descuento o un crédito a consumir</p>
    </div>

    <div class="flex-1 px-normal mt-2">
      <div class="flex items-center">

        <div class="flex-1 text-xl text-primary pl-sm">
          <p>{{ discount_type === 'prc' ? 'Descuento:' : 'Crédito:' }}</p>
        </div>

        <div class="flex-1 text-mid2xl text-primary">
          <p>{{ discount_value + `${discount_type === 'prc' ? '%' : '€'}` }}</p>
        </div>

        <div class="wrapper flex-1 flex text-center border border-dgray rounded-full overflow-hidden">

          <input type="radio" name="select" v-model="discount_type" value="prc"  id="option-1" @change="format">
          <input type="radio" name="select" v-model="discount_type" value="fixed_e2" id="option-2" @change="format">

          <label for="option-1" class="flex flex-1 items-center option option-1 py-small rounded-r-full">
            <span class="flex-auto">
              <span class="title block text-xl font-bold">%</span>
            </span>
          </label>

          <label for="option-2" class="flex flex-1 items-center option option-2 rounded-l-full py-small">
            <span class="flex-auto">
              <span class="title block text-xl font-bold">€</span>
            </span>
          </label>
        </div>
      </div>

      <div class="mt-8">
        <input v-if="discount_type === 'prc'"
          type="range" min="10" max="100" step="5"
          v-model="discount_value"
          @change="$emit('update:discountValue', parseInt($event.target.value))"
          class="w-full custom-input-text align-middle">
        <input v-if="discount_type === 'fixed_e2'"
          type="range" min="10" max="200" step="5"
          v-model="discount_value"
          @change="$emit('update:discountValue', parseInt($event.target.value))"
          class="w-full custom-input-text align-middle">
      </div>
      <div class="flex text-lg mt-2">
        <p class="flex-1 text-left">{{ `${discount_type === 'prc' ? '10%' : '10€'}`}}</p>
        <p class="flex-1 text-right">{{ `${discount_type === 'prc' ? '100%' : '200€'}`}}</p>
      </div>
<!--      <Range v-model="discount" :step="5" :min="0" :max="100" class="mt-8"/>-->
    </div>

    <div class="flex-1">

    </div>
  </div>

</template>

<script>
// import Range from 'primevue/slider'

export default {
  props: {
    discountType: { required: false, type: String, default: 'prc' },
    discountValue: { required: false }
  },
  emits: ['update:discountValue', 'update:discountType'],
  data () {
    return {
      discount_value: 100,
      discount_type: 'prc'
    }
  },
  methods: {
    format () {
      this.discount_value = 100
      this.$emit('update:discountType', this.discount_type)
    }
  },
  mounted () {
    this.discount_value = this.discountValue
    this.discount_type = this.discountType
    window.scrollTo(0, 0)
    // console.log(this.discount_value, this.discount_type)
  }
}
</script>

<style scoped>
.wrapper .option{
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  color: #efefef;
  transition: all 0.3s ease;
}
input[type="radio"]{
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2{
  /*border-color: #cf1a1a;*/
  background: #397EF3;
  color: #fff;
}
.option-1{
  margin-right: -15px;
}
/*#option-1:checked:checked ~ .option-1{*/
/*  border-top-right-radius: 50%;*/
/*  border-bottom-right-radius: 50%;*/
/*}*/

/*#option-2:checked:checked ~ .option-2{*/
/*  !*border-color: #cf1a1a;*!*/
/*  border-top-left-radius: 20%;*/
/*  border-bottom-left-radius: 20%;*/
/*}*/

/*#option-1:checked:checked ~ .option-1 title,*/
/*#option-2:checked:checked ~ .option-2 title,*/
/*  !*border-color: #cf1a1a;*!*/
/*  background: #397EF3 !important;*/
/*}*/

</style>
